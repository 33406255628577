import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { ToggleNavService } from '../../services/toggle-nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  sub: Subscription;
  show: boolean = false;


  constructor(public toggleNav: ToggleNavService, public dataSVC: DataService) { }

  ngOnInit(): void {
    this.sub = this.dataSVC.entradas$.subscribe(entradas => {
      if(entradas.filter(e => e.publicado).length) this.show = true;
    });
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

}
