<div class="container">
    <mat-progress-bar mode="determinate" value="100" color="primary"></mat-progress-bar>
    <mat-toolbar class="toolbar">
        <img src="assets/logos/contacto_humano.svg" width="200" alt="">
        <span class="spacer"></span>
        <div class="navigate">
            <div class="links">
                <ul>
                    <a routerLink="">
                        <li>inicio</li>
                    </a>
                    <a routerLink="/nosotros">
                        <li>nosotros</li>
                    </a>
                    <a routerLink="/servicios">
                        <li>servicios</li>
                    </a>
                    <a routerLink="/noticias" *ngIf="show">
                        <li>noticias</li>
                    </a>
                    <a routerLink="/mi-cv">
                        <li>mi cv</li>
                    </a>
                </ul>
            </div>
            <div class="icons">
                <button mat-icon-button>
                    <mat-icon svgIcon="icon_facebook" color="primary" class="customIcons"></mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon svgIcon="icon_instagram" color="primary" class="customIcons"></mat-icon>
                </button>
                <button mat-icon-button (click)="toggleNav.opened = !toggleNav.opened">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </div>
    </mat-toolbar>
</div>