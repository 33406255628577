import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class isLogged implements CanActivate {

    constructor(private authSVC: AuthService, private _router: Router) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.authSVC.user$.pipe(
            map(user => {                
                if (!user) {
                    this._router.navigateByUrl('/login');
                    return false;
                }
                return true;
            })
        );
    }

}