import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  toggleNav: boolean = true;

  constructor() { }

  show(): void {
    this.toggleNav = true;
  }

  hide(): void {
    this.toggleNav = false;
  }

  toggle(): void {
    this.toggleNav = !this.toggleNav;
  }
}