import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface UserI {
  uid?: string
  email: string
  password?: string
  nombre: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user$: Observable<UserI | null | undefined>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private _router: Router) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {        
        if (user) {
          return this.afs.doc<UserI>(`usuarios/${user.uid}`).valueChanges();
        }
        return of(null);
      }),
    )
  }

  async login(email: string, password: string): Promise<any> {
    try {
      const { user } = await this.afAuth.signInWithEmailAndPassword(email, password);
      return user;
    } catch (error) { return error }
  }

  async register(dataUser: UserI): Promise<any> {
    try {
      const { user } = await this.afAuth.createUserWithEmailAndPassword(dataUser.email, dataUser.password? dataUser.password : '');
      if(user) {
        this.updateUserData(user, dataUser);
        return user;
      }else return null;
    } catch (error) { throw new Error(error) }
  }

  private updateUserData(user: any, dataUser: UserI) {
    const userRef: AngularFirestoreDocument<UserI> = this.afs.doc(`usuarios/${user.uid}`);
    const data: UserI = {
      uid: user.uid,
      email: user.email,
      nombre: dataUser.nombre,
    };
    return userRef.set(data, { merge: true });
  }

  async logout(): Promise<void> {
    try {
      await this.afAuth.signOut();
      this._router.navigateByUrl('/');
    } catch (error) { throw new Error(error) }
  }
}