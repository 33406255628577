<div class="content">    
    <router-outlet></router-outlet>    
</div>

<div class="buttons-fixed no-print">
    <a href="https://api.whatsapp.com/send?phone=5493454390269&text=Consulta%20desde%20contactohumano.com.ar"
    class="whatsApp" [ngClass]="{'action-whatsApp': windowScrolled, 'hide-whatsApp': hideWapp}" target="_blank">
        <img src="../assets/icons/wapp.svg" alt="">
    </a>
    <div class="scroll-to-top" matRipple matRippleRadius="50" matRippleCentered="true"
        [ngClass]="{'show-scrollTop': windowScrolled}" (click)="scrollToTop()" *ngIf="customSvc.toggleNav && windowScrolled">
        <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
</div>