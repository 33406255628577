import { Component, HostListener, OnInit } from '@angular/core';
import { CustomService } from './shared/services/custom.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

enum Icons {
  face = 'icon_facebook',
  instagram = 'icon_instagram',
  user = 'man-user'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'contactoHumano';

  windowScrolled: boolean;
  hideWapp: boolean = false;

  constructor(
    public customSvc: CustomService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.registerIcons();
  }

  ngOnInit() { }

  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), 'assets/icons');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset < 300) {
      this.windowScrolled = false;
    }

    if (window.pageYOffset >= 3500) {
      this.hideWapp = true;
    }
    else if (this.hideWapp && window.pageYOffset <= 3500) {
      this.hideWapp = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
}
