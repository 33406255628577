import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { CurriculumI, EntradaI, ImagesI } from '../models/interfaces';
import { map, shareReplay } from 'rxjs/operators';
import { ItemI } from '../components/carousel/interfaces';
import { ParallaxI } from '../components/parallax/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _entradasColl: AngularFirestoreCollection<EntradaI>;
  entradas$: Observable<EntradaI[]>;

  private _instanciasCarrouselColl: AngularFirestoreCollection<ItemI>;
  instanciasCarrousel$: Observable<ItemI[]>;

  private _instanciasCurriculumnsCV: AngularFirestoreCollection<CurriculumI>;
  curriculumns$: Observable<CurriculumI[]>;

  private _instanciasImagenes: AngularFirestoreCollection<ImagesI>;
  images$: Observable<ImagesI[]>;

  private _instanciasImagenContact: AngularFirestoreCollection<{url: string}>;
  imageContact$: Observable<{url: string}[]>;

  private _instanciasImagenesParallax: AngularFirestoreCollection<ParallaxI>;
  imagesParallax$: Observable<ParallaxI[]>;

  public provs$: Observable<any>;
  public dptos$: Observable<any>;
  public localidades$: Observable<any>;

  constructor(private _http: HttpClient, private _afs: AngularFirestore) {
    this._entradasColl = _afs.collection<EntradaI>('entradas', ref => ref.orderBy('fecha', 'desc'));
    this.entradas$ = this.onGetAllEntradas();
    this._instanciasCarrouselColl = _afs.collection<ItemI>('carrousel', ref => ref.orderBy('pos', 'desc'));
    this.instanciasCarrousel$ = this.onGetAllInstancias();
    this._instanciasCurriculumnsCV = _afs.collection<CurriculumI>('curriculumns', ref => ref.orderBy('fechaDeCarga', 'desc'));
    this.curriculumns$ = this.onGetAllCV();
    this._instanciasImagenes = _afs.collection<ImagesI>('imagenes_home');
    this.images$ = this.onGetImages();

    this._instanciasImagenContact = _afs.collection<{url: string}>('imageContact', ref => ref.limit(1));
    this.imageContact$ = this.onGetImageContact();

    this._instanciasImagenesParallax = _afs.collection<ParallaxI>('imagenes_Parallax', ref => ref.orderBy('pos', 'desc'));
    this.imagesParallax$ = this.onGetImagesParallax();

    this.provs$ = this._getProvincias();
    this.dptos$ = this._getDepartamentos();
    this.localidades$ = this._getLocalidades();
  }

  onGetAllEntradas() {
    return this._entradasColl.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { ...data, id } as EntradaI;
        })
      }),
      shareReplay(1)
    );
  }

  addEntrada(entrada: EntradaI): Promise<DocumentReference> {
    return this._entradasColl.add(entrada);
  }

  updateEntrada(entrada: EntradaI): Promise<void> {
    return this._entradasColl.doc(entrada.id).update(entrada);
  }

  deleteEntrada(entradaID: string): Promise<void> {
    return this._entradasColl.doc(entradaID).delete();
  }

  //!
  onGetAllInstancias() {
    return this._instanciasCarrouselColl.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { ...data, id } as ItemI;
        })
      }),
      shareReplay(1)
    );
  }

  addInstancia(instancia: ItemI): Promise<DocumentReference> {
    return this._instanciasCarrouselColl.add(instancia);
  }

  updateInstancia(instancia: ItemI): Promise<void> {
    return this._instanciasCarrouselColl.doc(instancia.id).update(instancia);
  }

  deleteInstancia(id: string): Promise<void> {
    return this._instanciasCarrouselColl.doc(id).delete();
  }


  //!
  private _getProvincias(): Observable<any> {
    return this._http.get<any>('../../assets/JSON/provincias.json');
  }

  private _getDepartamentos(): Observable<any> {
    return this._http.get<any>('../../assets/JSON/departamentos.json');
  }

  private _getLocalidades(): Observable<any> {
    return this._http.get<any>('../../assets/JSON/localidades.json',);
  }

  //!
  onGetAllCV() {
    return this._instanciasCurriculumnsCV.snapshotChanges().pipe(map(
      actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { ...data, id } as CurriculumI;
        })
      }),
      shareReplay(1)
    );
  }

  addCV(curriculum: CurriculumI): Promise<DocumentReference> {
    return this._instanciasCurriculumnsCV.add(curriculum);
  }

  updateCV(curriculum: CurriculumI): Promise<void> {
    return this._instanciasCurriculumnsCV.doc(curriculum.id).update(curriculum);
  }

  deleteCV(id: string): Promise<void> {
    return this._instanciasCurriculumnsCV.doc(id).delete();
  }

  //!
  onGetImages() {
    return this._instanciasImagenes.valueChanges();
  }

  onGetImageContact() {
    return this._instanciasImagenContact.valueChanges();
  }

  onGetImagesParallax() {
    return this._instanciasImagenesParallax.valueChanges();
  }

  updateImg(img: string, url: string): Promise<void> {
    const id = 'ZPVf26jmUeckRahT34mE';
    return this._instanciasImagenes.doc(id).update({ [img]: url });
  }

  updateIMGContact(url: string): Promise<void> {
    const id = 'C6wQItqrZ6IYtRHHw3uu';
    return this._instanciasImagenContact.doc(id).update({ url: url });
  }

  updateImgParallax(url: string, pos: number): Promise<void> {
    let id: string;
    if(pos === 1) id = 'ngdVpnxzdq2CHfg7a7RU';
    else if(pos === 2) id = 'hUSqbgcaVmBkLpfbwrZK';

    return this._instanciasImagenesParallax.doc(id).update({ imgUrl: url });
  }
  
  updateTextParallax(text: string, pos: number): Promise<void> {
    let id: string;
    if(pos === 1) id = 'ngdVpnxzdq2CHfg7a7RU';
    else if(pos === 2) id = 'hUSqbgcaVmBkLpfbwrZK';

    return this._instanciasImagenesParallax.doc(id).update({ text: text });
  }
}
